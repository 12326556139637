import React, { useState, useEffect } from 'react'
import Header from '../../../components/Yard/common/Header'
import "./Equipment.css"
import { connect } from "react-redux"
import { navigate } from 'gatsby'
import { Link } from "gatsby";
import CustomButton from '../../../components/Yard/Button/Button';
import EquipmentDet from './comp/EquipmentDet';
import EquipmentDoc from './comp/EquipmentDoc';
import { Button, Col, message,Row,Spin } from 'antd';
import {Routes} from '../../../utils/route'
import {Image} from 'antd';
import { IndustryType, renderImage } from '../../../utils/helper';
import YardDetails from '../../../components/data-display/card/YardDetails';
import Head from "../../../components/Yard/header/Header"
const Equipment = (props) => {
    const id = props.id
    const [loading, setLoading] = useState (true);
    const [state, setState] = useState(null);
        useEffect(()=>{
            const callback=(val)=>{
                if(val===true){
                    setLoading(false);
                    if(props.yardHandData){ 
                        filterEquipmentDetails()
                    }
                }}
              Routes('yard',callback)
        
    },[])

    const filterEquipmentDetails = () =>{
        
        const filteredEquipment = props.yardHandData?.filter((val,index) =>{
            
            if(val.ticket_ref_id == id){
                return val
            }
        })
        
        if(filteredEquipment && filteredEquipment.length > 0){
            setState(...filteredEquipment)
            message.success({ content: `FETCHED EQUIPMENT DATA`, duration: 2 });
        }
        
    }
    if(!loading){
    return (
        <>
             <Head/>
            <section className="equipment">
               <Header headName="Equipment Details" LinkName="/yard/home" />
               <div className="equipment_container">
               {state ?
               <> 
                <EquipmentDet data={state}/>
                <EquipmentDoc data={state.asset_txns_details.asset_washout_docs}/>
               </>
               :<div>FETCHING EQUIPMENT DETAILS</div>}
               <div className='equipment_oil_thired' onClick={()=>navigate(`/yard/inspection/${id}`)}> start inspection</div>
                </div>

                {/* :
                     <YardDetails button={<Link to={`/yard/inspection/${id}`}>
                     <Button style={{color:"white",background:'grey'}}>START INSPECTION</Button>
                     </Link>}  data={state} id={id}/> */}
            </section>
        </>
    )
}else{
    return(<div className="spinner-main"><Spin/></div>)
  }
}

const mapStateToProps = state => ({
    
    yardHandData:state.yardHandData
})
export default connect(mapStateToProps, null)(Equipment)

